import request from '@/utils/request'

const contentApi = {
    getSponsorUrl: '/api/sysMeeting/getShowRoomInfo',
}

/**
 * 获取赞助商列表
 * @param params
 * @returns {*}
 */
export function getSponsorList() {
    return request({
        url: contentApi.getSponsorUrl,
        method: 'get',
        params: { meetingId: process.env.VUE_APP_ID}
    })
}
